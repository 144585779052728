import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {ManageMembersComponent} from './components/managemembers/managemembers.component';
import {AnalyticsComponent} from './components/analytics/analytics.component';
import {InvitationsComponent} from './components/invitations/invitations.component';
import {AdManagerComponent} from './components/admanager/admanager.component';
import {ProfileComponent} from './components/profile/edit-profile.page';
import {ManageWellspaceComponent} from './components/managewellspace/managewellspace.component';
import {CategoriesComponent} from './components/categories/categories.component';
import {SettingsAndPermissionsComponent} from './components/settingsandpermissions/settingsandpermissions.component';
import {AuthGuard} from './guards/auth/auth.guard';
import {LoginComponent} from './components/login/login.component';
import {TeampaymentsComponent} from './components/teampayments/teampayments.component';
import {SuperadminGuard} from './guards/superadmin/superadmin.guard';
import {TokenComponent} from './components/token/token.page';
import {BillingComponent} from './components/billing/billing.component';
import {PurchasesComponent} from './components/purchases/purchases.component';
import {TeamCompaniesComponent} from './components/team-companies/team-companies.component';
import {AppSettings} from './app.settings';
import {IntegrationHomeComponent} from './components/integrationhome/integrationhome.component';
import {InsuranceBrokersComponent} from './components/insurance-brokers/insurance-brokers.component';
import {InsuranceCarriersComponent} from './components/insurance-carriers/insurance-carriers.component';
import {InsuranceEmployersComponent} from './components/insurance-employers/insurance-employers.component';
import {InsuranceUsersComponent} from './components/insurance-users/insurance-users.component';
import {AdvertisingAdsComponent} from './components/advertising-ads/advertising-ads.component';
import {AdvertisingAdvertisersComponent} from './components/advertising-advertisers/advertising-advertisers.component';
import {AdvertisingCampaignsComponent} from './components/advertising-campaigns/advertising-campaigns.component';
import {AdvertisingCreativesComponent} from './components/advertising-creatives/advertising-creatives.component';
import {AdvertisingFlightsComponent} from './components/advertising-flights/advertising-flights.component';
import {AdvertisingImpressionsComponent} from './components/advertising-impressions/advertising-impressions.component';
import {MediaDocumentsComponent} from './components/media-documents/media-documents.component';
import {MediaContentComponent} from './components/media-content/media-content.component';
import {MediaImagesComponent} from './components/media-images/media-images.component';
import {AdminActionsComponent} from './components/admin-actions/admin-actions.component';
import {ReconciliationComponent} from './components/reconciliation/reconciliation.component';
import {BrokeradminComponent} from './components/externalbrokeradmin/brokeradmin.component';
import {ManagerAdminGuard} from './guards/manageradmin/manageradmin.guard';
import {InsuranceProfileComponent} from './components/insuranceprofile/insuranceprofile.component';
import {EmbeddedGuard} from './guards/embedded/embedded.guard';
import { EmailCodeComponent } from './components/email-code/email-code.component';
import {ManageusersComponent} from './components/customersupport/manageusers/manageusers.component';
import {ManagesupportagentsComponent} from './components/customersupport/managesupportagents/managesupportagents.component';
import {ClientFeatureGuard} from './guards/client-feature/client-feature.guard';
import { CanDeactivateGuard } from './guards/can-deactivate/can-deactivate.guard';
import {ReportsComponent} from './components/customersupport/reports/reports.component';
import {BrokerComponent} from './components/brokeragency/broker/broker.component';
import {ClientsComponent} from './components/brokeragency/clients/clients.component';
import {AgencyComponent} from './components/brokeragency/agency/agency.component';
import {ProductDetailsComponent} from './components/brokeragency/product-details/product-details.component';
import {MemberEnrollComponent} from './components/brokeragency/member-enroll/member-enroll.component';
import {VendorGroupsComponent} from './components/brokeragency/vendor-groups/vendor-groups.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:showTimeout',
    component: LoginComponent,
  },
  {
    path: '',
    component: AppSettings.isIntegration() ? IntegrationHomeComponent : HomeComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'reports',
    loadChildren: () => import('./components/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'clients',
    loadChildren: () => import('./components/clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [ AuthGuard, ClientFeatureGuard ]
  },
  {
    path: '2fa',
    loadChildren: () => import('./components/two-factor-authentication/two-factor-authentication.module').then(m => m.TwoFactorAuthenticationModule)
  },
  {
    path: 'billingreturn/:teamId',
    component: AppSettings.isIntegration() ? IntegrationHomeComponent : HomeComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'token/:token/:refresh',
    component: TokenComponent,
  },
  {
    path: 'token/:token/:refresh/:create',
    component: TokenComponent,
  },
  {
    path: 'managemembers',
    component: ManageMembersComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'invitations',
    component: InvitationsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'admanager',
    component: AdManagerComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'billing',
    redirectTo: 'billing/stripepaymentmethods'
  },
  {
    path: 'billing/stripecreditcard',
    component: BillingComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'billing/stripeavailablepaymentmethods',
    component: BillingComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'billing/stripepaymentmethods',
    component: BillingComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'billing/stripechargehistory',
    component: BillingComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'insuranceprofile',
    component: InsuranceProfileComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'insuranceprofile/embedded/:userId',
    component: InsuranceProfileComponent,
    canActivate: [ EmbeddedGuard ]
  },
  {
    path: 'emailcode/:email',
    component: EmailCodeComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'purchases',
    redirectTo: 'purchases/transactions'
  },
  {
    path: 'purchases/transactions',
    component: PurchasesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'purchases/awaitingapproval',
    component: PurchasesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'purchases/approved',
    component: PurchasesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'purchases/denied',
    component: PurchasesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'purchases/reimbursed',
    component: PurchasesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'purchases/configure',
    component: PurchasesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics',
    redirectTo: 'analytics/overview'
  },
  {
    path: 'analytics/overview',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/team-overview',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/categories',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/agency-members',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/approve/:userId',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/reimbursements',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/allowances',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/team-allowances',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/reimbursements/:userId',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/peer-to-peer',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/payment-report',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/allowance-allocation-report',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/spending-report',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'analytics/rewards-report',
    component: AnalyticsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'managewellspace/overview/:teamId',
    component: ManageWellspaceComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'managewellspace/categories/:teamId',
    component: ManageWellspaceComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'managewellspace/agency-members/:teamId',
    component: ManageWellspaceComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'managewellspace/plan/:teamId',
    component: ManageWellspaceComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'managewellspace/program/:teamId',
    component: ManageWellspaceComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'settingsandpermissions',
    component: SettingsAndPermissionsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'insurance/brokers',
    component: InsuranceBrokersComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'insurance/carriers',
    component: InsuranceCarriersComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'insurance/employers',
    component: InsuranceEmployersComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'insurance/users',
    component: InsuranceUsersComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'advertising/ads',
    component: AdvertisingAdsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'advertising/advertisers',
    component: AdvertisingAdvertisersComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'advertising/campaigns',
    component: AdvertisingCampaignsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'advertising/creatives',
    component: AdvertisingCreativesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'advertising/flights',
    component: AdvertisingFlightsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'advertising/impressions',
    component: AdvertisingImpressionsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'media/documents',
    component: MediaDocumentsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'media/content',
    component: MediaContentComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'media/images',
    component: MediaImagesComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'admin/actions',
    component: AdminActionsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'teampayments',
    component: TeampaymentsComponent,
    canActivate: [ SuperadminGuard ]
  },
  {
    path: 'products',
    redirectTo: 'products/companies'
  },
  {
    path: 'products/companies',
    component: TeamCompaniesComponent,
    canActivate: [ ManagerAdminGuard ]
  },
  {
    path: 'products/permissions',
    component: TeamCompaniesComponent,
    canActivate: [ ManagerAdminGuard ]
  },
  {
    path: 'products/subscriptions',
    component: TeamCompaniesComponent,
    canActivate: [ SuperadminGuard ]
  },
  {
    path: 'reconciliation/stripe/:customerId',
    component: ReconciliationComponent,
    canActivate: [ SuperadminGuard ]
  },
  {
    path: 'reconciliation/teamlog/:customerId',
    component: ReconciliationComponent,
    canActivate: [ SuperadminGuard ]
  },
  {
    path: 'reconciliation/apilog/:customerId',
    component: ReconciliationComponent,
    canActivate: [ SuperadminGuard ]
  },
  {
    path: 'externalbrokeradmin',
    component: BrokeradminComponent,
    canActivate: [ SuperadminGuard ]
  },
  {
    path: 'customersupport/managecustomers',
    component: ManageusersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customersupport/managesupportagents',
    component: ManagesupportagentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customersupport/reports',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/broker',
    component: BrokerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/clients',
    component: ClientsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/agency',
    component: AgencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/agency/agency-profile',
    component: AgencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/agency/agency-clients',
    component: AgencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/agency/agency-members',
    component: AgencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/agency/agency-producers',
    component: AgencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/clients/client-census',
    component: ClientsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/clients/client-eligibility',
    component: ClientsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/clients/client-enrollment',
    component: ClientsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/broker/broker-profile',
    component: BrokerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/broker/broker-agencies',
    component: BrokerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/broker/broker-products',
    component: BrokerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/product/details/:productId',
    component: ProductDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokeragency/member/enroll',
    component: MemberEnrollComponent
  },
  {
    path: 'vendorgroups',
    redirectTo: 'vendorgroups/products'
  },
  {
    path: 'vendorgroups/products',
    component: VendorGroupsComponent,
    canActivate: [ ManagerAdminGuard ]
  },
  {
    path: 'vendorgroups/plans',
    component: VendorGroupsComponent,
    canActivate: [ ManagerAdminGuard ]
  },
  {
    path: 'vendorgroups/rates',
    component: VendorGroupsComponent,
    canActivate: [ ManagerAdminGuard ]
  },
  {path: '**', redirectTo: ''}
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }