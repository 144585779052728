import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import {SecretService} from '../secret/secret.service';
import {SecretsInterface} from '../../interfaces/secrets/secrets.interface';
import {SnackBarComponent} from '../../components/snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface userCredentialsInterface {
    username: string;
    password: string;
}

@Injectable({
    providedIn: 'root'
})
export class CognitoMfaService {
    currentCognitoUser: any;
    private cognito: any;
    private secrets!: SecretsInterface;


    constructor(private secretService: SecretService, private snackBar: MatSnackBar) {
        this.getAppSecrets();
    }

    getAppSecrets(): void {
        this.secretService.secrets.subscribe({
            next: (secrets: SecretsInterface | null): void => {
                if (secrets) {
                    this.secrets = secrets;
                    this.configCognitoAwsSdk();
                }
            }
        });
    }

    configCognitoAwsSdk(): void {
        AWS.config.update({
            region: this.secrets.region,
            accessKeyId: this.secrets.accessKeyId,
            secretAccessKey: this.secrets.secretAccessKey
        });
        this.cognito = new AWS.CognitoIdentityServiceProvider();
    }

    async cognitoMFASignIn(userCredentials: userCredentialsInterface): Promise<CognitoUser>  {
        this.currentCognitoUser = await Auth.signIn(userCredentials);
        return this.currentCognitoUser;
    }

    async cognitoMFASignInWithoutPassword(email: string): Promise<any> {
        try {
            this.currentCognitoUser = await Auth.signIn(email);
            return this.currentCognitoUser;
        } catch (error: any) {
            if (JSON.stringify(error).includes('UserLambdaValidationException')) {
                this.snackBar.openFromComponent(SnackBarComponent, {data: 'You should login with your credentials before try to login without password!'});
            } else {
                this.snackBar.openFromComponent(SnackBarComponent, {data: error});
            }
        }
    }

    async cognitoCustomChallengeAnswer(code: string): Promise<any> {
        const customChallenge = await Auth.sendCustomChallengeAnswer(this.currentCognitoUser, code);
        if (customChallenge && customChallenge.signInUserSession) {
            return customChallenge;
        }
    }

    async cognitoMFASignUp(user: userCredentialsInterface): Promise<any> {
        const params = {
            AuthFlow: 'USER_PASSWORD_AUTH',
            ClientId: this.secrets.userPoolWebClientId,
            AuthParameters: {
                'USERNAME': user.username,
                'PASSWORD': user.password
            }
        };
        return this.cognito.initiateAuth(params).promise();
    }

    async checkIfUserExist(user: userCredentialsInterface): Promise<boolean | null> {
        const params = {
            UserPoolId: this.secrets.userPoolId,
            Username: user.username,
        };
        return new Promise((resolve, reject): void => {
            this.cognito.adminGetUser(params, (err: any): void => {
                if (err) {
                    if (err.code === 'UserNotFoundException') {
                        resolve(false);
                    } else {
                        reject(null);
                    }
                } else {
                    resolve(true);
                }
            });
        });
    }

    cognitoMFAConfirmSignIn(TOTPCode: string, mfaType: 'SOFTWARE_TOKEN_MFA'): Promise<any> {
        return Auth.confirmSignIn(this.currentCognitoUser, TOTPCode, mfaType);
    }

    async cognitoSetupTOTP(): Promise<string> {
        await this.cognitoUpdateCurrentUser();
        return Auth.setupTOTP(this.currentCognitoUser);
    }

    async cognitoVerifyTOTPToken(totpCode: string): Promise<CognitoUserSession > {
        await this.cognitoUpdateCurrentUser();
        return Auth.verifyTotpToken(this.currentCognitoUser, totpCode);
    }

    async cognitoSetPreferredMFA(mfaType: 'TOTP'): Promise<string> {
        await this.cognitoUpdateCurrentUser();
        return Auth.setPreferredMFA(this.currentCognitoUser, mfaType);
    }

    async cognitoRemoveMFA(): Promise<any> {
        await this.cognitoUpdateCurrentUser();
        await Auth.setPreferredMFA(this.currentCognitoUser, 'NOMFA');
        return Auth.forgetDevice();
    }

    async cognitoUpdateCurrentUser(): Promise<void> {
        await Auth.currentAuthenticatedUser().then((user: CognitoUser): void => {
            if (user) {
                this.currentCognitoUser = user;
            }
        });
    }

    async cognitoRegisterUserDevice(): Promise<any> {
        return await Auth.rememberDevice();
    }

    async getCurrentCognitoUser(): Promise<any> {
        await this.cognitoUpdateCurrentUser();
        return Auth.getPreferredMFA(this.currentCognitoUser);
    }

    async cognitoSignOut(): Promise<any> {
        return Auth.signOut();
    }



}
